// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Pagination from "https://framerusercontent.com/modules/L6zHqqMB9weqTlE8uMAv/8lXBVOoPSyjs336TGm7d/dotDxqtbY.js";
const PaginationFonts = getFonts(Pagination);

const cycleOrder = ["oDOoe3xuR", "cor2rGUPn", "uZIrk2jfj"];

const variantClassNames = {cor2rGUPn: "framer-v-1dntfeu", oDOoe3xuR: "framer-v-tyaveu", uZIrk2jfj: "framer-v-dupk74"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Page 1": "oDOoe3xuR", "Page 2": "cor2rGUPn", "Page 3": "uZIrk2jfj"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; press3?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "oDOoe3xuR", press3: mG62iyJJH, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "oDOoe3xuR", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1qtzif2 = activeVariantCallback(async (...args) => {
if (mG62iyJJH) {
const res = await mG62iyJJH(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AcOBr", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-tyaveu", className)} data-framer-name={"Page 1"} layoutDependency={layoutDependency} layoutId={"oDOoe3xuR"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({cor2rGUPn: {"data-framer-name": "Page 2", "data-highlight": true, onTap: onTap1qtzif2}, uZIrk2jfj: {"data-framer-name": "Page 3"}}, baseVariant, gestureVariant)}><motion.div className={"framer-7vcga9-container"} layoutDependency={layoutDependency} layoutId={"onlJ5uyc8-container"} transition={transition}><Pagination height={"100%"} id={"onlJ5uyc8"} layoutId={"onlJ5uyc8"} page={"1"} style={{width: "100%"}} variant={"kUfDJTefJ"} width={"100%"} {...addPropertyOverrides({cor2rGUPn: {variant: "D2Y18Kse0"}, uZIrk2jfj: {variant: "D2Y18Kse0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1fgbs3w-container"} layoutDependency={layoutDependency} layoutId={"ia1um2uKG-container"} transition={transition}><Pagination height={"100%"} id={"ia1um2uKG"} layoutId={"ia1um2uKG"} page={"2"} style={{width: "100%"}} variant={"D2Y18Kse0"} width={"100%"} {...addPropertyOverrides({cor2rGUPn: {variant: "kUfDJTefJ"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-epa1h2-container"} layoutDependency={layoutDependency} layoutId={"uCZGPxT2v-container"} transition={transition}><Pagination height={"100%"} id={"uCZGPxT2v"} layoutId={"uCZGPxT2v"} page={"3"} style={{width: "100%"}} variant={"D2Y18Kse0"} width={"100%"} {...addPropertyOverrides({uZIrk2jfj: {variant: "kUfDJTefJ"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AcOBr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AcOBr * { box-sizing: border-box; }", ".framer-AcOBr .framer-11o7fua { display: block; }", ".framer-AcOBr .framer-tyaveu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-AcOBr .framer-7vcga9-container, .framer-AcOBr .framer-1fgbs3w-container, .framer-AcOBr .framer-epa1h2-container { flex: none; height: auto; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-AcOBr .framer-tyaveu { gap: 0px; } .framer-AcOBr .framer-tyaveu > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-AcOBr .framer-tyaveu > :first-child { margin-left: 0px; } .framer-AcOBr .framer-tyaveu > :last-child { margin-right: 0px; } }", ".framer-AcOBr.framer-v-1dntfeu .framer-tyaveu { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 144
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"cor2rGUPn":{"layout":["auto","auto"]},"uZIrk2jfj":{"layout":["auto","auto"]}}}
 * @framerVariables {"mG62iyJJH":"press3"}
 */
const FramerOsokKsvtK: React.ComponentType<Props> = withCSS(Component, css, "framer-AcOBr") as typeof Component;
export default FramerOsokKsvtK;

FramerOsokKsvtK.displayName = "Elements/Pagination Numbers Copy 2";

FramerOsokKsvtK.defaultProps = {height: 51, width: 144};

addPropertyControls(FramerOsokKsvtK, {variant: {options: ["oDOoe3xuR", "cor2rGUPn", "uZIrk2jfj"], optionTitles: ["Page 1", "Page 2", "Page 3"], title: "Variant", type: ControlType.Enum}, mG62iyJJH: {title: "Press 3", type: ControlType.EventHandler}} as any)

addFonts(FramerOsokKsvtK, [...PaginationFonts])